import '../common/styles/SamStyles.scss'
import '@/common/manager/ConsoleMessage'
import '@/common/manager/I18n'
import {setTheWindowAnalyticsObjectBasedOnThePages} from '@/common/util/Tracking'
import SamLogin from '@/standalone-login/SamLogin'
import {LOGIN_PAGES} from '@/standalone-login/SamLoginPages'
import {createRoot} from 'react-dom/client'

setTheWindowAnalyticsObjectBasedOnThePages(LOGIN_PAGES, document.location.pathname, 'initial-load')

const container = document.createElement('div')
container.classList.add('app')
document.body.appendChild(container)

const root = createRoot(container)
root.render(<SamLogin />)
